var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('div',{staticClass:"flex justify-between items-center flex-wrap mb-2"},[_c('h3',{staticClass:"text-2xl"},[_vm._v("Prelaciones")]),(_vm.user.isAdmin)?_c('a-button',{staticClass:"w-full md:w-auto",attrs:{"to":{ name: 'create_prelation' }}},[_vm._v(" Crear prelación ")]):_vm._e()],1),_c('a-table',{attrs:{"columns":_vm.columns,"source":_vm.source,"loading":_vm.loading || _vm.loadingPeriod,"toolbar-column-filter":"","pagination":_vm.pagination},on:{"paginate":function($event){return _vm.search({ page: $event })},"toolbar(refresh)":function($event){return _vm.search({ page: 1 })}},scopedSlots:_vm._u([{key:"toolbar(search)",fn:function(){return [_c('a-input',{attrs:{"is-search":"","placeholder":"Buscar..."},on:{"search":function($event){return _vm.search({ search: $event, page: 1 })}}})]},proxy:true},(_vm.user.isStudent)?{key:"toolbar",fn:function(){return [_c('a-period-accessor',{attrs:{"disabled":""},model:{value:(_vm.query.period),callback:function ($$v) {_vm.$set(_vm.query, "period", $$v)},expression:"query.period"}})]},proxy:true}:{key:"toolbar",fn:function(){return [_c('a-period-accessor',{model:{value:(_vm.query.period),callback:function ($$v) {_vm.$set(_vm.query, "period", $$v)},expression:"query.period"}})]},proxy:true},{key:"td(type)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.type === 'partial' ? 'Parcial' : 'Total')+" ")])]}},{key:"td(prelations)",fn:function(ref){
var item = ref.item;
return [(_vm.user.isAdmin)?[(item.type === 'partial')?_c('div',{staticClass:"flex gap-2 flex-wrap"},_vm._l((item.courses),function(prelation,key){return _c('a-badge',{key:key,on:{"close":function($event){return _vm.removePrelation({
              originating_course_id: item.id,
              period_id: item.period_id,
              semester_id: item.semester.id,
              target_courses_ids: [prelation.id],
              type: 'partial'
            })}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"p-1"},[_c('a-icon',{staticClass:"text-red-500",attrs:{"name":"trash"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(prelation ? prelation.title : '')+" ")])}),1):_c('div',{staticClass:"flex gap-2 flex-wrap"},[_c('a-badge',{on:{"close":function($event){return _vm.removePrelation({
              originating_course_id: item.id,
              period_id: item.period_id,
              semester_id: item.semester.id,
              target_courses_ids: null,
              id: item.prelation_id,
              type: 'full',
            })}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('div',{staticClass:"p-1"},[_c('a-icon',{staticClass:"text-red-500",attrs:{"name":"trash"}})],1)]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(("Semestre " + (item.semester_number) + " - " + (item.pensum.name)))+" ")])],1)]:[(item.type === 'partial')?_c('div',{staticClass:"flex gap-2 flex-wrap"},_vm._l((item.courses),function(prelation,key){return _c('a-badge',{key:key},[_vm._v(" "+_vm._s(prelation ? prelation.title : '')+" ")])}),1):_c('div',{staticClass:"flex gap-2 flex-wrap"},[_c('a-badge',[_vm._v(" "+_vm._s(("Semestre " + (item.semester_number) + " - " + (item.pensum.name)))+" ")])],1)]]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }