<template>
  <div class="p-1">
    <div class="flex justify-between items-center flex-wrap mb-2">
      <h3 class="text-2xl">Prelaciones</h3>
      <a-button :to="{ name: 'create_prelation' }" v-if="user.isAdmin" class="w-full md:w-auto">
        Crear prelación
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="source"
      :loading="loading || loadingPeriod"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <template v-if="user.isStudent" #toolbar>
        <a-period-accessor disabled v-model="query.period" />
      </template>
      <template v-else #toolbar>
        <a-period-accessor v-model="query.period" />
      </template>

      <template #td(type)="{ item }">
        <div>
          {{ item.type === 'partial' ? 'Parcial' : 'Total' }}
        </div>
      </template>
      <template #td(prelations)="{ item }">
        <template v-if="user.isAdmin">
          <div class="flex gap-2 flex-wrap" v-if="item.type === 'partial'">
            <a-badge
              @close="removePrelation({
                originating_course_id: item.id,
                period_id: item.period_id,
                semester_id: item.semester.id,
                target_courses_ids: [prelation.id],
                type: 'partial'
              })"
              v-for="(prelation, key) in item.courses"
              :key="key">
              {{ prelation ? prelation.title : '' }}
  
              <template #icon>
                <div class="p-1">
                  <a-icon name="trash" class="text-red-500" />
                </div>
              </template>
            </a-badge>
          </div>
          <div v-else class="flex gap-2 flex-wrap" >
            <a-badge
                @close="removePrelation({
                originating_course_id: item.id,
                period_id: item.period_id,
                semester_id: item.semester.id,
                target_courses_ids: null,
                id: item.prelation_id,
                type: 'full',
              })">
              {{`Semestre ${item.semester_number} - ${item.pensum.name}` }}
  
              <template #icon>
                <div class="p-1">
                  <a-icon name="trash" class="text-red-500" />
                </div>
              </template>
            </a-badge>
          </div>
        </template>
        <template v-else>
          <div class="flex gap-2 flex-wrap" v-if="item.type === 'partial'">
            <a-badge
              v-for="(prelation, key) in item.courses"
              :key="key">
              {{ prelation ? prelation.title : '' }}
            </a-badge>
          </div>
          <div v-else class="flex gap-2 flex-wrap" >
            <a-badge>
              {{`Semestre ${item.semester_number} - ${item.pensum.name}` }}
            </a-badge>
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    columns: [
      { title: 'Materia', key: 'title', enabled: true },
      { title: 'tipo', slot: 'type', enabled: true },
      { title: 'Priva', slot: 'prelations', enabled: true }
    ],
    query: {
      limit: 50,
      search: '',
      period: null,
      with: 'targetCourse,originatingCourse,pensum.semesters'
    }
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.prelations.pagination,
      loading: state => state.prelations.loading,
      loadingPeriod: state => state.periods.loading

    }),
    source() {

      let courses = {}

      this.pagination.data
        .forEach($0 => {
          if (!courses.hasOwnProperty($0.originating_course_id)) {
            courses[$0.originating_course_id] = {
              courses: this.pagination.data
                  .filter($1 => $1.originating_course_id === $0.originating_course_id)
                  .map($1 => $1.target_course),
              prelation_id: $0.id,
              period_id: $0.period_id,
              pensum: $0.pensum,
              semester: $0.pensum.semesters.find($1 => $1.number === $0.originating_semester_number),
              ...$0.originating_course,
              type: $0.type,
              semester_number: $0.target_semester_number
            }
          }
        })

      return Object.values(courses).map($0 => $0)
    }
  },
  watch: {
    'query.period'() {
      if(this.user.isStudent){
        this.search({...this.query, specialization: this.user.student.latest_inscription.specialization_id})
      }else{
        this.search({...this.query})
      }
    }
  },
  methods: {
    ...mapActions({
      fetchPrelations: 'prelations/fetchAll',
      deleteActivity: 'prelations/delete',
      deletePrelations: 'prelations/delete'
    }),
    removePrelation(payload) {
      this.deletePrelations(payload)
        .then(() => {
          this.fetchPrelations({ ...this.query, page: this.$route.query.page || 1 })
        })
    },
    search(query) {
      this.fetchPrelations({ ...this.query, ...query }).finally(() => this.loading = false)
    }
  },
  // mounted() {
  // }
}
</script>
